import React from "react";
import {useItems} from "./items-hooks";
import {FaTrash} from "react-icons/fa";
import axios from 'axios';
import {Form,Button} from "react-bootstrap";


export default function Item({id, title, total}) {
    const {removeItem, setItemTotal, setItemText} = useItems();

    const handleTextChange = (event) => {
        setItemText(id, event.target.value);
    }

    const handleTotalChange = (event) => {
        setItemTotal(id, event.target.value);
    }

    return (
        <article className="item">
            <Form.Group>
                <Form.Label>
                    Posma (aktivitātes) nosaukums: &nbsp;
                    <Button onClick={() => removeItem(id)}>
                        <FaTrash/>
                    </Button>
                </Form.Label>
                <Form.Control type="text"
                              value={title}
                              onChange={handleTextChange}
                              placeholder="Ievadiet aktivitātes nosaukumu"/>
            </Form.Group>
            <Form.Group>
                <Form.Label>
                    Aktivitātes pilnā samaksa bez PVN (bez avansa maksājuma atskaitījuma):</Form.Label>
                <Form.Control type="number"
                              step='0.01'
                              placeholder="Ievadiet summu"
                              value={total}
                              onChange={handleTotalChange}/>
            </Form.Group>


            < /article>
                )
                ;
                }
