import React from "react";
import {useItems} from "./items-hooks";
import {FaTrash} from "react-icons/fa";


export default function ItemPrinted({id, text, total, vat, total_w_vat, idx}) {

    const {getWords, advancedPercentage} = useItems();


    const addZeroes = (num) => {
        num = String(num);
        const dec = num.split('.')[1]
        const len = dec && dec.length > 2 ? dec.length : 2
        return String(Number(num).toFixed(len)).replace(".", ",")
    }


    total = addZeroes(Math.round(total * 100 * (1 - advancedPercentage/100)) / 100);
    total_w_vat = addZeroes(Math.round(total_w_vat * 100 * (1 - advancedPercentage/100)) / 100);
    vat = addZeroes(Math.round(vat * 100 * (1 - advancedPercentage/100)) / 100);

    const {total_w_vat_words, total_words, vat_words} = (getWords(id));


    return (
        <article className="item">
            <p>
                3.4.{idx + 2}. 10 (desmit) dienu laikā no brīža, kad ar Darba etapa Aktu Izpildītājs nodevis un
                Pasūtītājs
                pieņēmis Darba uzdevumā noteiktā Darba {idx + 1}. uzdevuma "{text}" nodevumus un rēķina saņemšanas
                no Izpildītāja, Pasūtītājs samaksā Izpildītājam {total_w_vat} EUR ({total_w_vat_words}), ko veido
                samaksas par Darbu daļu bez PVN {total} EUR ({total_words}) apmērā un
                PVN {vat} EUR ({vat_words}) apmērā;
            </p>

        </article>
    );
}
