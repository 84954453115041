import React from "react";
import Item from "./Item";
import {useItems} from "./items-hooks";
import {Form} from "react-bootstrap";


export default function ItemList() {
    const {
        items, totalAmt, setTotal, advancedPercentage,
        setadvancedPercentage
    } = useItems();


    return (
        <>
            <Form.Group>
                <Form.Label>Līguma kopējā summa bez PVN:</Form.Label>
                <Form.Control type="number" placeholder="Ievadiet summu" step='0.01' value={totalAmt}
                              onChange={(event) => setTotal(event.target.value)}/>

            </Form.Group>

            <Form.Group>
                <Form.Label> Avanss procentos:</Form.Label>
                <Form.Control type="number" placeholder="Ievadiet procentus" step='1'
                              value={advancedPercentage}
                              onChange={(event) => setadvancedPercentage(event.target.value)}/>
            </Form.Group>

            <div className="item-list">
                {items.length === 0 ? (
                    <p>Nav pievienoti posmi</p>
                ) : (
                    items.map(item => <Item key={item.id} {...item} />)
                )}
            </div>
        </>
    )
}
