import React from 'react';
import './App.css';
import {FaPlusCircle} from "react-icons/fa";
import ItemList from "./components/ItemList";
import {useItems} from "./components/items-hooks";
import ItemListPrinted from "./components/ItemListPrinted";
import {Row, Container, Col, Jumbotron,Form,} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';

import {Button} from "react-bootstrap";

function App() {

    const {addItem} = useItems();

    const submit = e => {
        e.preventDefault();
        addItem();
    };

    return (
            <Jumbotron>
                <Container>
                    <Row>
                        <Col><h1>Līguminators v1.0</h1></Col>
                    </Row>
                    <Row>
                        <Col>
                            <ItemList>
                            </ItemList>
                            <Form onSubmit={submit} id="add-news-item-form">

                                <Button onClick={submit}>Pievienot posmu <FaPlusCircle/></Button>
                            </Form>
                        </Col>

                        <Col>
                            <ItemListPrinted/>
                        </Col>

                    </Row>
                </Container>
            </Jumbotron>
    );
}

export default App;
