import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ItemsProvider} from "./components/items-hooks";


ReactDOM.render(
  <ItemsProvider>
    <App />
  </ItemsProvider>,
  document.getElementById('root')
);
