import React, {createContext, useState, useContext} from "react";
import {v4} from "uuid";
import axios from 'axios';

const ItemsContext = createContext();


export const ItemsProvider = ({children}) => {
    const [items, setItems] = useState([]);
    const [wordItems, setWordItems] = useState([]);
    const [totalAmt, setTotalState] = useState(0);
    const [advancedPercentage, setadvancedPercentageState] = useState(0);
    const [totalWords, setTotalWords] = useState({
        total_w_vat_words: "",
        total_words: "",
        vat_words: "",
        adv_total_w_vat_words: "",
        adv_total_words: "",
        adv_vat_words: "",
    });

    const setadvancedPercentage = (advancedPercentage) => {


        const advancedTotal = Math.round(100 * totalAmt * advancedPercentage / 100) / 100;
        const advancedTotalWVa = Math.round(100 * 1.21 * totalAmt * advancedPercentage / 100) / 100;
        const advancedVat = Math.round(100 * 0.21 * totalAmt * advancedPercentage / 100) / 100;

        const adv_vatPromise = axios.get(`/api/?amount=` + advancedVat);
        const adv_totalPromise = axios.get(`/api/?amount=` + advancedTotal);
        const adv_totalWVatPromise = axios.get(`/api/?amount=` + advancedTotalWVa);
        const percentagePromise = axios.get(`/api/?percentage=` + advancedPercentage);


        setadvancedPercentageState(advancedPercentage);


        Promise.all([adv_totalWVatPromise, adv_totalPromise, adv_vatPromise, percentagePromise])
            .then(function (results) {

                const adv_total_w_vat_words = results[0].data;
                const adv_total_words = results[1].data;
                const adv_vat_words = results[2].data;

                const percentage_words = results[3].data;


                setTotalWords(
                    {
                        ...totalWords,
                        adv_total_w_vat_words,
                        adv_total_words,
                        adv_vat_words,
                        percentage_words
                    }
                );


            });


    }

    const setTotal = (total) => {

        const total_w_vat = total * 1.21;
        const vat = total * 0.21;

        setTotalState(
            total
        );


        const advancedTotal = Math.round(100 * total * advancedPercentage / 100) / 100;
        const advancedTotalWVa = Math.round(100 * 1.21 * total * advancedPercentage / 100) / 100;
        const advancedVat = Math.round(100 * 0.21 * total * advancedPercentage / 100) / 100;


        const vatPromise = axios.get(`/api/?amount=` + vat);
        const totalPromise = axios.get(`/api/?amount=` + total);
        const totalWVatPromise = axios.get(`/api/?amount=` + total_w_vat);


        const adv_vatPromise = axios.get(`/api/?amount=` + advancedVat);
        const adv_totalPromise = axios.get(`/api/?amount=` + advancedTotal);
        const adv_totalWVatPromise = axios.get(`/api/?amount=` + advancedTotalWVa);

        const percentagePromise = axios.get(`/api/?percentage=` + advancedPercentage);

        Promise.all([totalWVatPromise, totalPromise, vatPromise, adv_totalWVatPromise, adv_totalPromise, adv_vatPromise, percentagePromise])
            .then(function (results) {
                const total_w_vat_words = results[0].data;
                const total_words = results[1].data;
                const vat_words = results[2].data;

                const adv_total_w_vat_words = results[3].data;
                const adv_total_words = results[4].data;
                const adv_vat_words = results[5].data;

                const percentage_words = results[6].data;


                setTotalWords(
                    {
                        total_w_vat_words,
                        total_words,
                        vat_words,
                        adv_total_w_vat_words,
                        adv_total_words,
                        adv_vat_words,
                        percentage_words
                    }
                );


            });

    }

    const addItem = () => {

        const id = v4();

        setItems([
            ...items,
            {
                id,
                "text": "",
                total: "",
                vat: "",
                total_w_vat: ""
            }
        ]);

        setWordItems([
            ...wordItems,
            {
                id
            }
        ]);
    }


    const setItemTotal = (id, total) => {


        const total_w_vat = total * 1.21;
        const vat = total * 0.21;


        setItems(
            items.map(item => (item.id === id ? {
                ...item,
                total,
                vat,
                total_w_vat
            } : item))
        );


        const vatPromise = axios.get(`/api/?amount=` + vat * (1 - advancedPercentage / 100));
        const totalPromise = axios.get(`/api/?amount=` + total * (1 - advancedPercentage / 100));
        const totalWVatPromise = axios.get(`/api/?amount=` + total_w_vat * (1 - advancedPercentage / 100));


        Promise.all([totalWVatPromise, totalPromise, vatPromise])
            .then(function (results) {
                const total_w_vat_words = results[0].data;
                const total_words = results[1].data;
                const vat_words = results[2].data;


                console.log(total, total_w_vat_words);

                setWordItems(
                    wordItems.map(item => (item.id === id ? {
                        ...item,
                        total_w_vat_words,
                        total_words,
                        vat_words
                    } : item))
                );


            });

    }

    const setItemText = (id, text) =>
        setItems(
            items.map(item => (item.id === id ? {...item, text} : item))
        );

    const removeItem = id => setItems(items.filter(item => item.id !== id));

    const getWords = id => wordItems.filter(item => item.id == id)[0];

    return (
        <ItemsContext.Provider value={{
            items,
            wordItems,
            getWords,
            addItem,
            removeItem,
            setItemTotal,
            setItemText,
            totalAmt,
            setTotal,
            totalWords,
            advancedPercentage,
            setadvancedPercentage
        }}>
            {children}
        </ItemsContext.Provider>
    );
};

export const useItems = () => useContext(ItemsContext);
