import React from "react";
import ItemPrinted from "./ItemPrinted";
import {useItems} from "./items-hooks";


export default function ItemListPrinted() {
    const {
        items, totalAmt, totalWords,
        advancedPercentage
    } = useItems();


    const addZeroes = (num) => {
        num = String(num);
        const dec = num.split('.')[1]
        const len = dec && dec.length > 2 ? dec.length : 2
        return String(Number(num).toFixed(len)).replace(".", ",")
    }


    const {
        total_w_vat_words,
        total_words,
        vat_words,
        adv_total_w_vat_words,
        adv_total_words,
        adv_vat_words,
        percentage_words
    } = totalWords;


    const total = addZeroes(Math.round(totalAmt * 100) / 100);
    const total_w_vat = addZeroes(Math.round(totalAmt * 1.21 * 100) / 100);
    const vat = addZeroes(Math.round(totalAmt * 0.21 * 100) / 100);

    const advancedTotal = addZeroes(Math.round(100 * totalAmt * advancedPercentage / 100) / 100);
    const advancedTotalWVa = addZeroes(Math.round(100 * 1.21 * totalAmt * advancedPercentage / 100) / 100);
    const advancedVat = addZeroes((Math.round(100 * 0.21 * totalAmt * advancedPercentage / 100) / 100));




    return (
        <div className="item-list-printed">
            <div>
                <p>3.1. Par pilnā apjomā paveiktu Darbu Pasūtītājs kopā samaksā Izpildītājam EUR {totalAmt} ({total_words}) un pievienotās vērtības nodokli (turpmāk – PVN) 21%
                    (divdesmit viens procents) apmērā jeb EUR {vat} ({vat_words}). Samaksa par Darbu kopā ar PVN ir
                    EUR {total_w_vat} ({total_w_vat_words}) (turpmāk – Līgumcena).</p>
                <p>3.2. Līgumcenas samaksu Pasūtītājs veic, pamatojoties uz Izpildītāja izsūtītajiem rēķiniem, ar
                    pārskaitījumu uz Izpildītāja norādīto norēķinu kontu. Izpildītājs var sagatavot rēķinus elektroniski
                    un nosūtīt tos uz Līguma 2.5.1. punktā norādītā Pasūtītāja pārstāvja e-pasta adresi. Puses vienojas,
                    ka elektroniskie rēķini ir derīgi bez paraksta.</p>
                <p>3.3. Ja mainās normatīvajos aktos valstī noteiktā PVN likme, tad PVN likmes samazināšanas gadījumā
                    attiecīgi tiek samazināta Līgumcena, savukārt PVN likmes palielināšanas gadījumā Līgumcena tiek
                    attiecīgi palielināta un likmes palielinājumu sedz Pasūtītājs. Izpildītājs šajā punktā paredzētās
                    Līgumcenas izmaiņas atspoguļo rēķinā.</p>
                <p>3.4. Līgumcenas samaksu Pasūtītājs veic pa daļām šādā secībā:</p>
                <p>3.4.1. 10 (desmit) dienu laikā pēc Līguma noslēgšanas un rēķina saņemšanas no Izpildītāja, Pasūtītājs
                    samaksā Izpildītājam avansa maksājumu {advancedPercentage}% ({percentage_words} procenti) apmērā no Līgumcenas jeb EUR &nbsp;
                    {advancedTotalWVa} ({adv_total_w_vat_words}), ko veido samaksas
                    par Darbu daļa bez PVN {advancedTotal} EUR ({adv_total_words}) apmērā
                    un PVN {advancedVat} EUR ({adv_vat_words}) apmērā.
                    Izpildītājs uzsāk Darba izpildi pēc avansa maksājuma saņemšanas. Par avansa maksājuma kavējuma laiku
                    pagarinās Darba izpildes termiņš;</p>

            </div>
            {items.length === 0 ? (
                <p></p>
            ) : (
                items.map((item, idx) => <ItemPrinted key={item.id} {...item} idx={idx}/>)
            )}
        </div>
    )
}
